<template>
    <div>
        <div class="row" v-if="$route.path == '/teacherManualModular'">
            <div class="modular-list" v-if="$i18n.locale=='zh'">
                <table v-if="$route.query.id == 0">
                    <tr v-for="(item,index) in $store.state.teacherClassList" :key="index">
                        <td> <span>{{index+1}}</span> </td>
                        <td>{{item.name_cn}}</td>
                        <!--                    <td> <i class="des">分类介绍</i> </td>-->
                        <td>{{item.activeNums}}/{{item.question_count}}</td>
                        <td> <a @click="starZuTi(item.id,item.name_cn,index)">{{$t('home.STARTs')}}</a> </td>
                    </tr>
                </table>
                <table v-else>
                    <tr v-for="(item,index) in $store.state.zhishiDianList" :key="index">
                        <td> <span>{{index+1}}</span> </td>
                        <td>{{item.name_cn}}</td>
                        <!--                    <td> <i class="des">分类介绍</i> </td>-->
                        <td>{{item.activeNums}}/{{item.question_count}}</td>
                        <td> <a @click="starZuTi(item.id,item.name_cn,index)">{{$t('home.STARTs')}}</a> </td>
                    </tr>
                </table>
            </div>
            <div class="modular-list" v-if="$i18n.locale=='en'">
                <table v-if="$route.query.id == 0">
                    <tr v-for="(item,index) in $store.state.teacherClassList" :key="index">
                        <td> <span>{{index+1}}</span> </td>
                        <td>{{item.name_en}}</td>
                        <!--                    <td> <i class="des">分类介绍</i> </td>-->
                        <td>{{item.activeNums}}/{{item.question_count}}</td>
                        <td> <a @click="starZuTi(item.id,item.name_en,index)">{{$t('home.STARTs')}}</a> </td>
                    </tr>
                </table>
                <table v-else>
                    <tr v-for="(item,index) in $store.state.zhishiDianList" :key="index">
                        <td> <span>{{index+1}}</span> </td>
                        <td>{{item.name_en}}</td>
                        <!--                    <td> <i class="des">分类介绍</i> </td>-->
                        <td>{{item.activeNums}}/{{item.question_count}}</td>
                        <td> <a @click="starZuTi(item.id,item.name_en,index)">{{$t('home.STARTs')}}</a> </td>
                    </tr>
                </table>
            </div>
            <div class="modular-botton">
                <div class="modular-botton-left" v-if="$route.query.id == 0">
                    {{$store.state.xuanzeTiMuList.length}}/{{getItem('exam_total')}}
                </div>
                <div class="modular-botton-left" v-if="$route.query.id == 1">
                    {{$store.state.xuanzeTiMuListZhi.length}}/{{getItem('exam_total')}}
                </div>
                <div class="modular-botton-right">
                    <q-btn class="modular-botton-right-btn" unelevated  flat :label="$t('home.GeneratePaper')" @click="generateEaam(activeTiNum)"  />
                </div>
            </div>
            <q-dialog v-model="alert">
                <q-card>
                    <q-card-section>
                        <div class="text-h6">提示</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none">
                        您选择的题目数量不足,请继续选择
                    </q-card-section>

                    <q-card-actions align="right">
                        <q-btn flat label="知道了" color="primary" v-close-popup />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </div>
        <router-view v-else></router-view>
    </div>

</template>

<script>
    export default {
        name: "ManualModular",
        data(){
            return{
                list:[],
                type:'',
                alert:false
            }
        },
        created() {
            console.log(this.$route.query.id)
            this.$route.query.id == 0 ? this.$route.meta.title = '分类': this.$route.meta.title = '知识点'
            this.$route.query.id == 0 ? this.getTypeList(): this.getZhiShiDianList()
            this.$route.query.id == 0 ? this.type='classify': this.type='knowledge'
        },
        watch:{
            "$store.state.topQueDing"() {
                if (this.$store.state.topQueDing == '/teacherManualModular'){
                    this.generateEaam()
                }
            }
        },
        methods:{
            // 跳转
            starZuTi(id,name,index){
                this.$router.push({path:'/questionBank',query:{type:this.type,id:id}})
                localStorage.setItem('className',name)
                // alert(name)
                sessionStorage.setItem('activeIndex',index)
            },
            // 获取知识点列表
            getZhiShiDianList(){
                this.$http.post('exam/knowledge_list').then(res =>{
                    if (this.$store.state.zhishiDianList.length === 0){
                        res.data.forEach(item =>{
                            item.activeNums = 0
                        })
                        console.log()
                        this.$store.state.zhishiDianList = res.data
                    }else {
                        this.$store.state.zhishiDianList.forEach((item,index)=>{
                            res.data[index].activeNums = item.activeNums
                        })
                        this.$store.state.zhishiDianList = res.data
                    }

                })
                console.log('知识点')
            },
            // 获取分类列表
            getTypeList(){
                this.$http.post('exam/classify_list').then(res =>{
                    console.log(this.$store.state.teacherClassList)
                    if (this.$store.state.teacherClassList.length === 0){
                        res.data.forEach(item =>{
                            item.activeNums = 0
                        })
                        this.$store.state.teacherClassList = res.data
                    }else {
                        this.$store.state.teacherClassList.forEach((item,index)=>{
                            res.data[index].activeNums = item.activeNums
                        })
                        this.$store.state.teacherClassList = res.data
                    }
                })
                console.log('分类')
            },
            // 生成手动试卷 exam/end_formation
            generateEaam(){
                    console.log('组题')
                    let question_ids = null
                    if (this.$route.query.id == 0){
                        question_ids = this.$store.state.xuanzeTiMuList.join(',')
                    }else {
                        question_ids = this.$store.state.xuanzeTiMuListZhi.join(',')
                    }
                    console.log(question_ids)
                    if (question_ids == ''){
                        this.$q.notify({
                            type: "negative",
                            position: "top",
                            timeout: "500",
                            message: this.$t('home.PleaseSelectATopicFirst'),
                        });
                        return
                    }
                    if (question_ids.split(',').length > localStorage.getItem('exam_total')){
                        let chaochuNum = question_ids.split(',').length - (localStorage.getItem('exam_total')-0)
                        this.$q.notify({
                            type: "negative",
                            position: "top",
                            timeout: "500",
                            message: this.$t('home.TheCurrentlySelectedTopicHasExceeded')+chaochuNum+this.$t('home.individual')+','+this.$t('home.Pleasereducebeforegenerating'),
                        });
                        return;
                    }
                    this.$http.post('exam/end_formation',{
                        type:'exam_teacher',
                        exam_id:localStorage.getItem('zujuan_id'),
                        question_ids: question_ids
                    }).then(res =>{
                        this.$q.notify({
                            type: 'positive',
                            position:'top',
                            timeout:'500',
                            message: res.msg
                        })
                        this.$store.state.ListByExamId = []
                        this.$store.state.xuanzeTiMuList = []
                        this.$store.state.xuanzeTiMuListZhi = []
                        this.$store.state.teacherClassList = []
                        this.$store.state.zhishiDianList = []
                        this.$router.push({path:'/teacherGroupQuestions'})
                    }).catch(err =>{
                        this.$q.notify({
                            type: 'negative',
                            position:'top',
                            timeout:'500',
                            message: err.msg
                        })
                    })
            }
        },
        computed:{
            activeTiNum(){
                let num = JSON.parse(localStorage.getItem('xuanzeTiMuList'))
                console.log('选中题目',num)
                if (num){
                    return num.length
                }else {
                    return 0
                }
            }
        }
    }
</script>

<style scoped lang="less">
.modular-list{
    width: 100%;
    height: 419px;
    overflow: auto;
    padding: 0 33px;
    padding-top: 20px;
    table{
        width: 100%;
        tr{
            width: 100%;
            height: 50px;
            background: #F4F4F4;
            border-radius: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 31px;
            td{

                font-size: 18px;
                span{
                    width: 22px;
                    height: 22px;
                    display: inline-block;
                    border: 1px solid #626262;
                    text-align: center;
                    line-height: 22px;
                    font-size: 18px;
                }
                a{
                    color: #ED711C;
                }
                .des{
                    font-size: 14px;
                    color: #666666;
                }
                &:nth-child(2){
                    width: 300px;
                }
            }
        }
    }
}
    .modular-botton{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 69px;
        padding-top: 20px;
        border-top: 2px solid #ED711C;
        .modular-botton-left{
            font-size: 18px;
            color: #ED711C;
            margin-left: 59px;
        }
        .modular-botton-right-btn{
            width: 112px;
            height: 34px;
            background: #ED711C;
            color: #ffffff;
            border-radius: 13px;
            margin-right: 70px;
        }
    }
</style>